import React, { useContext } from 'react';
import Sheet from "react-modal-sheet";
import moment from 'moment';
import { convertTimezone } from '../../../../../helpers/common';
import eventContext from '../../../../../contexts/EventContext';

const SessionModal = ({ showModal, onCloseModal, selectedSession, professionalTemplate }) => {
    const { eventData } = useContext(eventContext)
    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    const sessionTime = (val) => {
        let startTime = getDate(`${val.start_timestamp}`, "h:mm A");
        let endTime = getDate(`${val.end_timestamp}`, "h:mm A")
        let timezone = moment.tz(`${val.end_timestamp}`, eventData.time_zone).format("z");

        return `${startTime} to ${endTime} ${timezone}`
    }
    
    return (
        <div>
            <Sheet isOpen={showModal} onClose={() => onCloseModal()} detent="content-height">
                <Sheet.Container className="sheet-con" style={{
                    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50px 50px 0px 0px"
                }}>
                    <Sheet.Header>
                        <div className="p-3 mt-2">
                            <i
                                className="fas fa-times closeIcon d-flex justify-content-end"
                                onClick={() => onCloseModal()}
                            ></i>
                        </div>
                        <div className="row p-3 mt-2">
                            <div className="col-12 agenda-modal-title-section">
                                <div className="d-flex justify-content-between">
                                    <span className="agenda-session-title">
                                        {selectedSession.session_title}
                                    </span>

                                </div>
                            </div>
                            <div>
                                <div className="col-12 agenda-modal-date-section">
                                    <sapn className="agenda-modal-date">{sessionTime(selectedSession)}</sapn>
                                </div>
                            </div>
                        </div>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="p-3">
                            <div className="sheet-body mb-5">
                                <span className="agenda-session-desc">{selectedSession.session_description}</span>
                            </div>
                            {selectedSession.session_speakers.length > 0 && selectedSession.session_speakers.map((val) =>
                                <div className="agenda-speaker d-flex align-items-center justify-content-between p-3">
                                    <div className="d-flex align-items-center">
                                        <img src={val.image_url} className="agends-speaker-img" />
                                        <div className='about-speaker-div'>
                                            <p className="speaker-name">{val.name}</p>
                                            <p className="speaker-designation">{val.designation}</p>
                                            <p className="speaker-designation">{val.organisation}</p>
                                        </div>
                                    </div>
                                    <div className="social-icon">
                                        {val.twitter_url && <a href="" target="_blank" rel="noopener noreferrer" className="fab fa-twitter social-icon text-decoration-none" />}
                                        {val.linkedin_url && <a href="" target="_blank" rel="noopener noreferrer" className="fab fa-linkedin-in social-icon text-decoration-none" style={{ marginLeft: 20 }} />}
                                        {val.facebook_url && <a href="" target="_blank" rel="noopener noreferrer" className="fab fa-facebook-f social-icon text-decoration-none" style={{ marginLeft: 20 }} />}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>

                <Sheet.Backdrop />
            </Sheet>

            <style jsx>{`
          .closeIcon {
            color: #999999;
            font-size: 24px;
            position: absolute;
            right: 1.2em;
            cursor: pointer;
          }
          .agenda-session-title{
            font-family: Prompt;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            // text-align: left;            
          }
          .agenda-modal-date-section{
            margin-top: 30px;
        } 
        .agenda-modal-date{
            font-family: 'Prompt';
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: ${professionalTemplate ? "#FDB14B" : "#FB5850"};    
            background:  ${professionalTemplate ? "#FFF4E6" : "#FFF1F0"};
            border-radius: 5px;
            padding: 6px 13px;
            line-height: 27px;
        }
        .agenda-session-desc{
            font-family: Prompt;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

        }
        .agenda-speaker{
            border: 1px solid #0000001A;
            border-radius: 2px;
            margin-bottom: 20px;
        }
        .agends-speaker-img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        .about-speaker-div{
            margin-left: 15px;
        }
        .speaker-name{
            font-family: 'Prompt';
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #4551C0;
        }
        .speaker-designation{
            font-family: 'Prompt';
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #333333;
        }
        .social-icon{
            color: #333333;
        }
        `}</style>
        </div>
    )
}

export default SessionModal
