import React from 'react';
import Select, { components } from "react-select";

const DateDropdown = ({isSearchable, className, options, onChange, defaultValue, styles, icon}) => {
    const ValueContainer = ({ children, ...props }) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    {!!children && (
                        <i
                            class={icon}
                            aria-hidden="true"
                            style={{ position: 'absolute', left: 6 }}
                        />
                    )}
                    {children}
                </components.ValueContainer>
            )
        );
    };

    return (
        <Select
            isSearchable={isSearchable}
            className={className ? className : "select"}
            options={options}
            defaultValue={defaultValue}
            components={{ ValueContainer }}
            onChange={onChange}
            styles={styles}
        />
    )
}

export default DateDropdown
