import React, { useState } from 'react'
import { convertTimezone } from '../../../../../helpers/common'
import { motion } from "framer-motion"
import moment from 'moment'
import SessionModal from './SessionModal';

const Session = ({ selectedTrackData, eventData, professionalTemplate }) => {
    const [openModal, setOpenModal] = useState(false)
    const [selectedSession, setSelectedSession] = useState(false)
    const trackSessions = [...selectedTrackData[0].track_sessions]
    const timezone = eventData.time_zone

    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    const date = (session) => {
        let start_month = getDate(`${session.start_timestamp}`, 'MMM Do');
        // let end_month = getDate(`${session.end_timestamp}`, 'MMM Do');
        let start_year = getDate(`${session.start_timestamp}`, 'YYYY');
        // let end_year = getDate(`${session.end_timestamp}`, 'YYYY');
        let start_time = getDate(`${session.start_timestamp}`, "h:mm A");
        // let end_time = getDate(`${session.end_timestamp}`, "h:mm A");
        let timezone = moment.tz(`${session.end_timestamp}`, eventData.time_zone).format("z");

        return `${start_month}, ${start_year} at ${start_time} (${timezone})`
    }

    function convertToHTML(session) {
        return { __html: `${session.session_description}` };
    }
    return (
        <>
            {!_.isEmpty(trackSessions) ? (
                <>
                    {trackSessions.map(session => (
                        <div className='d-flex flex-lg-row flex-column mb-3' key={`${session.session_id}`}>
                            <div className='mb-2 me-1' style={{ minWidth: "120px", color: "white", fontWeight: "600" }}>
                                {getDate(session.start_timestamp, "h:mm")} - {getDate(session.end_timestamp, "h:mm")}
                            </div>
                            <motion.button
                                className='session-details py-2 px-3 w-100 text-start'
                                style={{ border: "0.8px solid #B4B4B4", borderRadius: "6px",  backgroundColor: session.session_colour }}
                                whileHover={{ scale: 1.03 }}
                                onClick={() => {
                                    if(session.session_description || session.session_speakers.length > 0) {
                                        setOpenModal(prevState => !prevState)
                                        setSelectedSession(session)
                                    }
                                }}
                            >
                                <div className='d-flex session-title-container d-flex justify-content-between'>
                                    <p className='session-title mb-1'>{session.session_title}</p>
                                    <div className='ms-2 mt-1'><i className="fa-regular fa-calendar"></i></div>
                                </div>
                                {session.session_description && <p className='session-description mb-0'>{session.session_description}</p>}
                                {session.session_speakers.length > 0 && <div className='session-speakers-container mt-2'>
                                    {session.session_speakers.map((speaker) => (
                                        <div className='py-1 px-2 speaker-agenda-container d-inline-block rounded-pill'>
                                            <div className='d-flex h-100 align-items-center ml-2'>
                                                <img src={speaker.image_url} className='speaker-img-agenda me-2 rounded-circle' alt='Speaker image'/>
                                                <span style={{ fontSize: "14px" }}>{speaker.name}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>}
                            </motion.button>
                            <style jsx>{`
                                .session-title {
                                    font-size: 1.25rem;
                                    font-weight: 700;
                                }
                                .session-description {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; /* number of lines to show */
                                    -webkit-box-orient: vertical;
                                }
                                .speaker-img-agenda {
                                    width: 22px;
                                }
                                .speaker-agenda-container {
                                    border: 0.6px solid #B4B4B4;
                                    // margin-left: 10px;
                                }

                                @media (min-width: 992px) and (max-width: 1368px) {
                                    .session-title {
                                        font-size: 1.125rem;
                                    }
                                }
                            `}</style>
                        </div>
                    ))}
                </>
            ) : (
                <div>Sessions for this track are yet to be updated</div>
            )}

            {openModal && <SessionModal 
                    selectedSession={selectedSession}
                    showModal={openModal}
                    onCloseModal={() => setOpenModal(false)}
                    professionalTemplate={professionalTemplate}
                    />}
        </>
    )
}

export default Session
