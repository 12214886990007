import { rawAxios as axios } from "./axios";
import {api} from "./axios.config";

const API_URL = api.apiUrl;

const GET_TICKETS = "/event/url/"
const GET_EVENTS = "/event/public/"

export function getEventData(url) {
	return axios.get(API_URL + GET_EVENTS + url);
}

export function getTicketData(url) {
	return axios.get(API_URL + GET_TICKETS + url + "/tickets");
}

export function getSpeakers(url) {
	return axios.get(API_URL + GET_EVENTS + url + "/speakers");
}

export function getSponsors(url) {
	return axios.get(API_URL + GET_EVENTS + url + "/sponsors");
}

export function getWorkshops(url) {
	return axios.get(API_URL + GET_EVENTS + url + "/workshops");
}

export function getAgenda(event_id, payload) {
	let QUERY = `?track_date=${payload}`;
	return axios.get(API_URL + "/event/" + event_id  + "/public" + "/tracks" + QUERY);
}
