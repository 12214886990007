import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';
import AgendaCard from '../../../../components/AgendaCard';

const AgendaDesktop = ({ agenda, eventData, professionalTemplate }) => {
    const [newData, setNewData] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [sessionDetails, setSessionDetails] = useState()
    const [tableData, setTableData] = useState()
    const [trackData, setTrackData] = useState()
    const timeZoneData = eventData;
    let specialChar = /[^\w\s]/gi

    useEffect(() => {
        arrayDates()
        const table = agenda.map(data => {
            let tasks = data.track_sessions.map(task => {
                return {
                    track_title: data.track_title,
                    ...task
                }
            })
            return tasks;
        }).flat()
        setTableData(table)

        const tracks = agenda.map(data => data.track_title)
        setTrackData(tracks)

    }, [])


    const convertEndTime = (val) => {
        const timeZone = moment.utc(val.end_timestamp).tz(timeZoneData.time_zone)
        let endTime = moment(timeZone).format("HH:mm")
        return endTime;
    }
    const convertStartTime = (val) => {
        const timeZone = moment.utc(val.start_timestamp).tz(timeZoneData.time_zone)
        let startTime = moment(timeZone).format("HH:mm")
        return startTime;
    }


    const maxDate = agenda && Math.max(
        ...agenda.map(element => {
            const timeZoneEndTime = moment.utc(element.end_time, "HH:mm").tz(timeZoneData.time_zone)
            return parseInt(moment(timeZoneEndTime).format('HH:mm'))
        }),
    );

    const minDate = agenda && Math.min(
        ...agenda.map(element => {
            const timeZoneStatTime = moment.utc(element.start_time, "HH:mm").tz(timeZoneData.time_zone)
            return parseInt(moment(timeZoneStatTime).format('HH:mm'));
        }),
    );


    function arrayDates() {
        const startTimeString = minDate;
        const endTimeString = maxDate;
        const startTime = new Date(`2023-02-22 ${startTimeString}:00`);
        const endTime = new Date(`2023-02-22 ${endTimeString}:00`);
        let timeArray = [];
        while (startTime <= endTime) {
            const timeString = startTime.toLocaleTimeString([], {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit"
            });
            timeArray.push(timeString.split(" ")[0]);
            startTime.setMinutes(startTime.getMinutes() + 15);
        }
        setNewData(timeArray)

    }


    function getScheduleColumn() {
        const tracks = agenda.map((data) => data.track_title);
        let column = "";
        for (let i = 0; i < tracks.length; i++) {
            if (i == 0) {
                column += `[track-${tracks[i].split(" ").join("").toLowerCase().replaceAll(specialChar, "")}-start] 1fr `;
            } else {
                column += `[track-${tracks[i - 1].split(" ").join("").toLowerCase().replaceAll(specialChar, "")}-end track-${tracks[i].split(" ").join("").toLowerCase().replaceAll(specialChar, "")}-start] 1fr `;
            }
        }
        return column;
    }

    const getHeadingTrackId = (val) => {
        return 'track-' + val.split(" ").join("").toLowerCase().replaceAll(specialChar, "") + '-start';
    }

    const getHeadingTrackIdEnd = (val) => {
        return 'track-' + val.split(" ").join("").toLowerCase().replaceAll(specialChar, "") + '-end';
    }

    const getTimeSlotGrid = (date) => {
        let newTime = date.split(":").join("").split(" ")[0]
        return `time-${newTime}`;
    }

    const getSessionGrid = (task) => {
        return "track-" + task.session_title.split(" ").join("").toLowerCase().replaceAll(specialChar, "") + "-start";
    }

    const getTrackGrid = (task) => {
        return "track-" + task.track_title.split(" ").join("").toLowerCase().replaceAll(specialChar, "") + "-start";
    }

    const getGridRowForTask = (task) => {
        let starTime = `time-${convertStartTime(task).split(":").join("").split(" ")[0]}`
        let endTime = `time-${convertEndTime(task).split(":").join("").split(" ")[0]}`;
        return `${starTime} / ${endTime}`;
    }

    function showTime(time) {
        let newTime = time.split(" ")[0].split(":")[1];
        return moment(newTime).format("hh:mm")
        // return newTime == '15' || newTime == '45'

    }


    function convertGridTemplateRow() {

        const timeLoop = newData;

        let cssVarible = '';

        for (let i = 0; i < timeLoop.length; i++) {
            let name = timeLoop[i].split(":").join("");
            cssVarible += ` [time-${name}] 60px`
        }
        return cssVarible;
    }


    function getTotalTrack(val) {
        const first = val[0];
        const last = val[val.length - 1];
        return `track-${first.split(" ").join("").toLowerCase().replaceAll(specialChar, "")}-start / track-${last.split(" ").join("").toLowerCase().replaceAll(specialChar, "")}-end`
    }

    function getPadding(index, lastElement) {
        if (index == lastElement)
            return '10px 0 10px 6px';
        return '10px 6px'
    }

    return (
        <>

            <div className="section-view">
                <div className={agenda.length <= 1 ? "row pb-5 mt-5 d-flex justify-content-center pb-5" : "pb-5"}>
                    <div className={agenda.length <= 1 ? "col-md-9" : "overflow-property"}>
                        <div
                            className="schedule"
                            aria-labelledby="schedule-heading"
                            style={{
                                gridTemplateColumns: `[times] 4em ${getScheduleColumn()}`,
                                gridTemplateRows: `[trackData] auto ${convertGridTemplateRow()}`
                            }}
                        >
                            {/*track heading*/}
                            {trackData && trackData.map((track, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="agenda-track-card track-slot mb-4"
                                        aria-hidden="true"
                                        style={{
                                            gridColumn: `${getHeadingTrackId(track)}`,
                                            padding: `${getPadding(index, trackData.length - 1)}`
                                        }}
                                    >
                                        <p className="agenda-track-card-head"> {track}</p>
                                        <p className="agenda-track-card-subHead">{track.track_description}</p>
                                    </div>
                                )
                            })}
                            {/*vertical line*/}
                            {trackData && trackData.map((track, index) => {
                                return (
                                    <div
                                        key={index}
                                        className=""
                                        aria-hidden="true"
                                        style={{
                                            gridRow: `${convertGridTemplateRow()}`,
                                            gridColumn: `${getHeadingTrackIdEnd(track)}`,
                                            width: '100%'
                                        }}>
                                        {trackData.length - 1 != index &&
                                            <div style={{
                                                background: 'rgba(255, 255, 255, 0.2)',
                                                position: 'absolute',
                                                top: '12px',
                                                width: '2px',
                                                height: '100%'
                                            }}></div>}
                                    </div>
                                )
                            })}
                            {/*Time data*/}
                            {newData && newData.map((time, index) => {
                                return (
                                    <div
                                        className="time-slot"
                                        key={index}
                                        style={{
                                            gridRow: getTimeSlotGrid(time),
                                            gridTemplateRows: `${convertGridTemplateRow()}`
                                        }}>
                                        {time && moment(time, ["HH:mm"]).format("hh:mm")}
                                        {/*showTime(time) && time*/}
                                    </div>
                                )
                            })}
                            {/*horizontal line*/}
                            {newData && newData.map((time, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            gridColumn: getTotalTrack(trackData),
                                            gridRow: getTimeSlotGrid(time),
                                            width: '98%',
                                            height: '2px',
                                            background: 'rgba(255, 255, 255, 0.2)'
                                        }}>
                                    </div>
                                )
                            })}
                            {/*session data*/}
                            {tableData && tableData.map((task) => {
                                return (
                                    <div
                                        key={task.session_id}
                                        className={`agenda-session-card ${getSessionGrid(task)}, ${getTrackGrid(task)}`}
                                        style={{
                                            gridColumn: getTrackGrid(task),
                                            gridRow: getGridRowForTask(task),
                                            backgroundColor: task.session_colour
                                        }}
                                        onClick={() => {
                                            setOpenModal(true)
                                            setSessionDetails(task)
                                        }}

                                    >
                                        <p className="session-title">{task.session_title}</p>
                                        <div className="mt-2 session-description">{task.session_description}</div>
                                        <div className="d-flex speaker-container">
                                            {task.session_speakers.slice(0, 2).map(val =>
                                                <div className="speaker">
                                                    <img src={val.image_url} alt="speaker-image" className="speaker-image" />
                                                    <span className="agenda-speaker-name">{val.name}</span>
                                                </div>
                                            )}
                                            {
                                                task.session_speakers.length > 2 && agenda.length <= 4 && <div className="more-speaker">
                                                    <span className='plus-color'>+</span>{task.session_speakers.slice(2).length}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>




                        {openModal &&
                            <AgendaCard
                                isOpen={openModal}
                                closeModal={() => setOpenModal(false)}
                                sessionDetails={sessionDetails}
                                professionalTemplate={professionalTemplate}
                            />
                        }
                    </div>
                </div>
            </div>

            <style jsx>{`
            .overflow-property{
                overflow-x: scroll;   
                overflow-y: hidden;   
                white-space: nowrap;
            }
            .schedule {
                padding: 0 40px;
                display: grid;
                place-items: center;
                position:relative;  
              }
              .track-all {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ccc;
                color: #000;
                box-shadow: none;
              }
              .track-slot{
                  width: 100px;
                  text-align: center;
              }
            .time-slot {
                grid-column: times;
                height: 70%;
                width: 100%;
                padding: 5px;
                display: flex;
                align-items: center;
                color: white;
                font-family: 'Prompt';
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
              }
             
              .agenda-track-div{
                padding: 20px;
            }
            .agenda-track-card{
               text-align: center;
                width: 100%;   
                height: 80px;
            }
            .agenda-track-card-head{
                padding: 0 10px;
                font-family: 'Prompt';
                font-weight: 700;
                font-size: 24px;
                line-height: 120%;
                color: ${professionalTemplate ? "black" : "#572148"};    
                margin-bottom: 0px;
                background:#ffff;
                height: inherit;
                width:100%;
                display:flex;
                align-items:center;
                justify-content:center;
                border-radius: 10px;  
                border: ${professionalTemplate ? "5px solid #FDB14B" : ""}
            }
            .agenda-track-card-subHead{
                font-family: 'Prompt';
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                color: #333333;
                margin-top: 10px
            }

            .agenda-session-card{
                margin-top: 59px;
                width: 96%;
                height: 90%;
                border-radius: 10px; 
                position: relative;
                cursor: pointer;
                padding: 20px;
                border-radius: 10px; 
                overflow: hidden;
            }

            .session-title{
                font-family: 'Prompt';
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1; 
                white-space: normal;
            }
            .session-description{
                font-family: 'Prompt';
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #000000;
                width: 100%;
                max-height: 38%;
                display: -webkit-box; /* Set the display to block to prevent inline content overflow */
                word-wrap: break-word;
                white-space: normal;
                overflow: hidden; 
               
                // overflow: hidden;
                // text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 1; /* number of lines to show */
                // -webkit-box-orient: vertical;
                // -o-text-overflow: ellipsis; 
                // width: 100%; 
                // white-space: normal;
            }
            .speaker-container{
                position: absolute;
                bottom: 5px;
            }
            .speaker{
                display: flex;
                align-items: center;
                margin-right: 20px;
                color: black;
            }
            .speaker-image{
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
            .agenda-speaker-name{
                font-family: 'Prompt';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #000000;
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                -webkit-box-orient: vertical;
                -o-text-overflow: ellipsis; 
                width: 100%; 
                white-space: normal;
            }
            .more-speaker{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);    
                font-size: 14px;
            }
            .plus-color{
                color: #FB5850;
            }
            `}</style>
        </>
    )
}

export default AgendaDesktop;
