import React from 'react'
import AgendaDesktop from './AgendaDesktop';
import { useMediaQuery } from 'react-responsive';
import AgendaMobile from './AgendaMobile/AgendaMobile';

const AgendaDesktopNMobile = ({
    agenda,
    eventData,
    setDateValue,
    dateValue,
    professionalTemplate,
    selectedTrackId,
    setSelectedTrackId,
    selectedBackground,
    background, 
    otherTextColor
}) => {

    const handleMediaQueryChange = (matches) => {
        smallerScreen = matches;
        // matches will be true or false based on the value for the media query
    }

    let smallerScreen = useMediaQuery(
        { maxWidth: 768 }, undefined, handleMediaQueryChange
    );


    return (
        <>
            {smallerScreen ? <AgendaMobile
                agenda={agenda}
                eventData={eventData}
                setDateValue={setDateValue}
                dateValue={dateValue}
                professionalTemplate={professionalTemplate}
                selectedTrackId={selectedTrackId}
                setSelectedTrackId={setSelectedTrackId}
                selectedBackground={selectedBackground}
                background={background}
                otherTextColor={otherTextColor}
            />
                : <AgendaDesktop
                    agenda={agenda}
                    eventData={eventData}
                    setDateValue={setDateValue}
                    dateValue={dateValue}
                    professionalTemplate={professionalTemplate}
                />
            }
        </>
    )
}

export default AgendaDesktopNMobile
