import React from 'react'
import Session from './Session';
import Tracks from './Track';

const AgendaMobile = ({
    agenda,
    eventData,
    professionalTemplate,
    selectedTrackId,
    setSelectedTrackId,
    selectedBackground,
    background,
    otherTextColor
}) => {
    return (
        <>
            <div className="agenda-div">
                {(!_.isEmpty(agenda)) && <Tracks
                    agendaTracks={agenda}
                    selectedTrackId={selectedTrackId}
                    setSelectedTrackId={setSelectedTrackId}
                    selectedBackground={selectedBackground}
                    background={background}
                    otherTextColor={otherTextColor}
                />}
                {(!_.isEmpty(agenda)) && <Session
                    selectedTrackData={agenda.filter((trackData) => trackData.track_id === selectedTrackId)}
                    eventData={eventData}
                    professionalTemplate={professionalTemplate}
                />}
            </div>
            <style jsx>{`
        .agenda-div{
            padding: 0 20px 20px 20px;
        }
    `}</style>
        </>
    )
}

export default AgendaMobile
